import React from "react"
import { Link } from "gatsby"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout-page"
import Message from "../components/PurchaseSuccess/SuccessMessage"

const PaymentSuccess = () => (
  <Layout>
    <SEO title="Purchase Success" />
    <PageLayout title="Success">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Card>
          <Message />
          <CardActions>
            <Link
               to={"/"}
               style={{ textDecoration: "none" }}
            >
              <Button variant='outlined'>Return Home</Button>
            </Link>
            <Link
              to={"/contact/"}
              style={{ textDecoration: "none" }}
            >
              <Button variant='outlined'>Contact Me</Button>
            </Link>
          </CardActions>
        </Card>
      </div>
    </PageLayout>
  </Layout>
)

export default PaymentSuccess;
