import React from "react"
import { Link } from "gatsby"

import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

const SuccessMessage = () =>
   <CardContent>
      <Typography variant="h6" color="textPrimary" gutterBottom>
         Thank you for your purchase!
      </Typography>
      <Typography variant="body1" color="textPrimary">
         Your order has now been received, and you will be receiving a confirmation
         email with your order details.
         <br />
         If you have any further questions or concerns please don't hesitate to{" "}
         <Link to="/contact/">contact me</Link> and I'll be happy to assist in anyway
         I can.
         <br />
         <br />
         Cheers,
         <br />
         Dave
      </Typography>
   </CardContent>

export default SuccessMessage;